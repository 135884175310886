import { useLoader } from "@react-three/fiber";
import {  TextureLoader, EquirectangularReflectionMapping, DoubleSide, } from "three";

const Diamond = () => {

const envMap = useLoader(TextureLoader, "/3D/textures/diamond4.png");
// const envMap = useLoader(TextureLoader, "/3D/textures/pz3.png");
envMap.mapping = EquirectangularReflectionMapping;

  return (
    <meshStandardMaterial
    color={0xFFFFF0}
    envMap={envMap}
    envMapIntensity={0.9}
    metalness={1}
    roughness={0}
    side={DoubleSide}
    />
  )
}

export default Diamond