import { useLoader } from "@react-three/fiber";
import { TextureLoader, Vector2, DoubleSide } from "three";

const TeethTexture = (props) => {

  // const {
    // backgroundColor,
  // } = props;

  const [normalMap, specMap] = useLoader(TextureLoader, [
    "/3D/textures/Mold_Normal.png",
    "/3D/textures/Mold_Refl_gloss.png",
  ])

  return (
    <meshPhongMaterial 
      // color={backgroundColor === 'white'?(0xFFFFFF):(0x000000)}
      color={0xEDE0DC}

      normalMap={normalMap}
      normalScale={new Vector2(0, 0.2)}
      specularMap={specMap}
      reflectivity={0.5}
      shininess={100}
      side={DoubleSide}
    />
  )
};

export default TeethTexture;
