import useWindowSize from "../../hooks/useWindowSize";

const Lights = (props) => {
  const { backgroundColor } = props;

  const windowSize = useWindowSize();

  if (backgroundColor === "white") {
    return (
      <>
        {windowSize.width >= 750 ? (
          <fog attach="fog" color={0xede0dc} near={4} far={5} />
        ) : (
          <fog attach="fog" color={0xede0dc} near={8} far={10} />
        )}
        <ambientLight intensity={0.81} color={0xFFFFFF}/>
        <pointLight position={[0, 0, 10]} intensity={0.3} color={0xFFFFFF}/>
      </>
    );
  } else {
    return (
      <>
        <fog attach="fog" color={0x000000} near={4} far={5} />
        <ambientLight intensity={6} />
        <pointLight position={[-10, 0, 10]} intensity={3} />
        <pointLight position={[10, 0, 10]} intensity={3} />
      </>
    );
  }
};

export default Lights;
