import { useState, useEffect } from "react";
import { PortableText } from "@portabletext/react";
import { UnderlineLink } from "../hooks/usePortableTextComponents";
import sanityClient from "../client";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import "./Terms.scss";
import Loading from "../components/global/Loading";

const Terms = () => {
  const [pageData, setPageData] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "termsConditions"][0]`)
      .then((data) => {
        setPageData(data);
      })
      .catch();
  }, []);

  if (pageData) {
    return (
      <motion.main
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.4 } }}
        exit={{ opacity: 0, transition: { duration: 0.4 } }}
        className="terms__outer-wrapper"
      >
        <Link to={"/"} className="terms__back-button">
          &larr; BACK
        </Link>
        <section className="terms__inner-wrapper">
            <PortableText
              value={pageData.termsInfo}
              components={UnderlineLink}
            />
         </section>
      </motion.main>
    );
  } else {
    return <Loading />
  }
};

export default Terms;