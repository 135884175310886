import { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import { Loader } from "../../hooks/loadingManager";

import RaycasterComponent from "./RaycasterComponent";
import Lights from "./Lights";
import Jewels from "./models/jewels/Jewels";
import Teeth from "./models/teeth/Teeth";

import Animations from "./Animation";

import "./Scene.scss";

const Scene = (props) => {

    const {
        raycasterObjects,
        setRaycasterObjects,
        isDragging,
        setIsDragging,
        jewelsArray,
        setJewelsArray,
        selectedJewel,
        setSelectedJewel,
        selectedJewelIndex,
        setSelectedJewelIndex,
        selectedTeeth,
        loadingProgress,
        setLoadingProgress,
        backgroundColor,
        foregroundColor,
        modelsGroup
    } = props

  return (
    <div className="scene__wrapper">
    <Canvas flat>

    <Suspense fallback={<Loader setLoadingProgress={setLoadingProgress} />}>
      <Lights backgroundColor={backgroundColor} />
      <RaycasterComponent
        {...{
          jewelsArray,
          setJewelsArray,
          selectedJewelIndex,
          raycasterObjects,
          isDragging,
        }}
      />
      <Animations
        {...{
          selectedJewel,
          modelsGroup,
        }}
      />
      <group ref={modelsGroup}>
        <Jewels
          {...{
            jewelsArray,
            setJewelsArray,
            setSelectedJewel,
            setSelectedJewelIndex,
            setIsDragging,
          }}
        />
        <Teeth
          {...{
            setRaycasterObjects,
            selectedTeeth,
            loadingProgress,
            setLoadingProgress,
            backgroundColor,
            foregroundColor,
          }}
        />
      </group>
      </Suspense>
    </Canvas>
  </div>
  )
}

export default Scene