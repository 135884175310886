import { useGLTF } from "@react-three/drei";
import { degToRad } from "three/src/math/MathUtils";
import Gold from "../../textures/Gold";
import Diamond from "../../textures/Diamond";

const Heart = (props) => {
  const { nodes } = useGLTF("/3D/models/Heart.gltf");
  const {
    jewelsArray,
    index,
    handlePointerDown,
    handlePointerEnter,
    handlePointerLeave,
  } = props;
  return (
    <group
      {...props}
      dispose={null}
      position={jewelsArray[index].position}
      rotation={jewelsArray[index].rotation}
      onPointerDown={() => handlePointerDown(index)}
      onPointerEnter={handlePointerEnter}
      onPointerLeave={handlePointerLeave}
      scale={[0.4, 0.4, 0.4]}
    >
      <group position={[0, 0, 0.15]} rotation={[degToRad(180), 0, 0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["HEART_DIAMOND_reduction_1e-06_tolerance_1"].geometry}
        >
          <Gold />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["HEART_DIAMOND_reduction_1e-06_tolerance_2"].geometry}
        >
          <Diamond />
        </mesh>
      </group>
    </group>
  );
};

useGLTF.preload("/3D/models/Heart.gltf");
export default Heart;
