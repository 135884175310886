import { useEffect, useRef } from "react";

import "./Cart.scss";

const Cart = (props) => {
  const { shopifyClient, ui } = props;
  const node = useRef();

  useEffect(() => {
    if (ui.createComponent && shopifyClient) {
      ui.createComponent("cart", {
        node: node.current,
        // moneyFormat: '${{amount}}',
        options: {
          toggle: {
            iframe: false,
            contents: {
              count: false,
              icon: false,
              title: false,
              cartButton: true,
            },
            text: {
              title: "",
            },
            order: ["cartButton"],
          },
          cart: {
            startOpen: false,
            iframe: false,
            popup: false
          },
        },
      });
    }
  }, [ui, shopifyClient]);

  return <div ref={node} />;
};

export default Cart;
