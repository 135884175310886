import useProgressiveImg from "../../hooks/useProgressiveImage";
import sanityClient from "../../client";
import imageUrlBuilder from "@sanity/image-url";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

const ProgressiveImage = (props) => {
  const { classNames, imageSrc, alt } = props;

  const smallUrl = `${urlFor(imageSrc)}?q=10`;
  const largeUrl = `${urlFor(imageSrc)}`;
  const [src, { blur }] = useProgressiveImg(smallUrl, largeUrl);

  return (
    <img
      src={src}
      alt={alt}
      className={classNames}
      style={{
        filter: blur ? "blur(20px)" : "none",
        transition: blur ? "none" : "filter 0.3s ease-out",
      }}
    />
  );
};

export default ProgressiveImage;
