import "./Preloader.scss"

const Preloader = (props) => {

  const {loadingProgress} = props;
  return (
    <div className={`preloader__outer-wrapper ${loadingProgress === 100?'disabled':''}`}>

      <div className="preloader__inner-wrapper">
        <img src="/assets/DollyCohen_Script.svg" alt="DOLLY COHEN" className="preloader__logo"/>

        <div className="preloader__loadbar-outer">
          <div className="preloader__loadbar-inner"
          style={{width: `${loadingProgress}%`}}></div>
        </div>
        <div className="preloader__text">LOADING</div>
      </div>
    </div>
  )
}

export default Preloader