import Silver from "../../textures/Silver";

const SilverShape = (props) => {
  const { 
    jewelsArray, 
    index, 
    handlePointerDown,
    handlePointerEnter,
    handlePointerLeave, 
  } = props;

  return (
    <mesh
      position={jewelsArray[index].position}
      rotation={jewelsArray[index].rotation}
      onPointerDown={() => handlePointerDown(index)}
      onPointerEnter={handlePointerEnter}
      onPointerLeave={handlePointerLeave}
      key={index}
      castShadow
      receiveShadow
    >
      <boxGeometry args={[0.16, 0.16, 0.05]} />
      <Silver />
    </mesh>
  );
};

export default SilverShape;