import { useRef } from "react";
import { useFrame } from "@react-three/fiber";
import { Raycaster, Vector2 } from "three";
import { PerspectiveCamera } from "@react-three/drei";
import useWindowSize from "../../hooks/useWindowSize";

function RaycasterComponent(props) {
  const {
    jewelsArray,
    setJewelsArray,
    selectedJewelIndex,
    raycasterObjects,
    isDragging,
  } = props;

  const windowSize = useWindowSize();

  const cameraRef = useRef();
  const raycasterRef = useRef(new Raycaster());

  useFrame(({ mouse }) => {
    raycasterRef.current.setFromCamera(
      new Vector2(mouse.x, mouse.y),
      cameraRef.current
    );

    let intersections;
    if (raycasterObjects !== []) {
      intersections = raycasterRef.current.intersectObjects(raycasterObjects);
    }

    if (isDragging) {
      const newJewelsArray = [...jewelsArray];
      if (intersections.length > 0) {
        newJewelsArray[selectedJewelIndex].position = intersections[0].point;
        newJewelsArray[selectedJewelIndex].rotation = [
          -intersections[0].face.normal.z * 0.3,
          intersections[0].face.normal.x,
          jewelsArray[selectedJewelIndex].rotation[2],
        ];
      } else {
        newJewelsArray[selectedJewelIndex].position = [mouse.x, mouse.y, 0];
        newJewelsArray[selectedJewelIndex].rotation = [
          jewelsArray[selectedJewelIndex].rotation[0],
          jewelsArray[selectedJewelIndex].rotation[1],
          jewelsArray[selectedJewelIndex].rotation[2],
        ];
      }
      setJewelsArray(newJewelsArray);
    }
  });

  return (
    <>
      {windowSize.width >= 750 ? (
        <PerspectiveCamera makeDefault position={[0, 0, 3]} ref={cameraRef} />
      ) : (
        <PerspectiveCamera makeDefault position={[0, 0, 6.5]} ref={cameraRef} />
      )}
    </>
  );
}

export default RaycasterComponent;
