import { useEffect, useRef } from "react";

import './BuyButton.scss';

const BuyButton = (props) => {
  const { productData, ui } = props;
  const node = useRef();

  useEffect(() => {
    if (node && productData) {
        ui.createComponent("product", {
            id: productData.shopifyID,
            node: node.current,
            // moneyFormat: '${{amount}}',
            options: {
              product: {
                buttonDestination: "cart",
                iframe: false,
                contents: {
                    title: false,
                    description: false,
                    quantity: false,
                    img: false,
                    variantTitle: false,
                    price: false
                },
                text: {
                    button: 'ADD TO CART'
                }
              },
              toggle: {
                iframe: false,
                styles:{
                  toggle:{
                    'display': 'none'
                  }
                }
              },
            },
          });
        }
      }, [ui, productData]);

  return (
    <div className="buy-button__wrapper" >
      <div ref={node} />
    </div>
    
    );
};

export default BuyButton;
