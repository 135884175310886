import CircleLarge from "./CircleLarge";
import CircleSmall from "./CircleSmall";
import Heart from "./Heart";
import Line from "./Line";
import Square from "./Square";
import SilverShape from "./SilverShape";

const Jewels = (props) => {

  const { 
    jewelsArray, 
    setIsDragging,
    setSelectedJewel,
    setSelectedJewelIndex,
  } = props;

  const components = {
    circleLarge: CircleLarge,
    circleSmall: CircleSmall,
    heart: Heart,
    line: Line,
    square: Square,
    silverShape: SilverShape
  };

  const handlePointerDown = (index) => {
    setIsDragging(true);
    setSelectedJewel(jewelsArray[index]);
    setSelectedJewelIndex(index);
    document.body.style.cursor = 'grabbing'
    window.addEventListener("pointerup", endDrag);
  };

  const handlePointerEnter = () => {
    document.body.style.cursor = 'grab'
  };

  const handlePointerLeave = () => {
    document.body.style.cursor = 'default'
  };

  const endDrag = () => {
    setIsDragging(false);
    document.body.style.cursor = 'grab'
    window.removeEventListener("pointerup", endDrag);
  };


  return (
    <>
      {jewelsArray !== [] &&
        jewelsArray.map((component, index) => {
          const Component = components[component.name];
          return (
            <Component
              {...props}
              index={index}
              key={index}
              component={component}
              handlePointerDown={handlePointerDown}
              handlePointerEnter={handlePointerEnter}
              handlePointerLeave={handlePointerLeave}
            />
          );
        })}
    </>
  );
};

export default Jewels;
