import { useState } from "react";
// import ColorSchemeSelector from "./ColorSchemeSelector";
// import TeethSelector from "../components/interface/TeethSelector";
import JewelRotation from "../components/interface/JewelRotation";
// import { Link } from "react-router-dom";

import "./Home.scss";

const Home = (props) => {
  const {
    jewelsArray,
    setJewelsArray,
    selectedJewel,
    setSelectedJewel,
    selectedJewelIndex,
  } = props;

  const [newJewelIndex, setNewJewelIndex] = useState(1);

  const handleAddJewel = (e) => {
    const value = e.target.dataset.value;
    const stageName = e.target.dataset.title;
    const newJewelsArray = [...jewelsArray];
    newJewelsArray.push({
      name: value,
      stageName: stageName,
      position: [0, 0, 0],
      rotation: [0, 0, 0],
      index: newJewelIndex,
    });
    setJewelsArray(newJewelsArray);
    setSelectedJewel(jewelsArray[newJewelIndex]);
    setNewJewelIndex((newJewelIndex) => (newJewelIndex += 1));
  };

  const handleClearJewels = () => {
    setJewelsArray([]);
    setSelectedJewel(null);
  };

  const handleClosePositions = () => {
    setSelectedJewel(null);
  };

  const handleRemoveJewel = () => {
    const newJewelsArray = jewelsArray.filter((t) => t !== selectedJewel);
    setJewelsArray(newJewelsArray);
    setSelectedJewel(null);
  };

  const handleResetJewel = () => {
    const newJewelsArray = [...jewelsArray];
    newJewelsArray[selectedJewelIndex].position = [0, 0, 0];
    newJewelsArray[selectedJewelIndex].rotation = [0, 0, 0];
    setJewelsArray(newJewelsArray);
  };

  const [hoveredJewel, setHoveredJewel] = useState(null);

  const handleMouseEnter = (e) => {
    setHoveredJewel(e.target.dataset.title);
  };
  const handleMouseLeave = (e) => {
    setHoveredJewel(null);
  };

  return (
    <>
      <div
        className={`nav__position-jewel-wrapper ${
          selectedJewel ? "active" : ""
        }`}
      >
        <h1 className="nav__add-jewel-title">
          {selectedJewel ? selectedJewel.stageName : ""}
        </h1>
        <div className="nav__position-jewels-controls">
          <JewelRotation {...props} />
          <div className="nav__range-wrapper">
            <button onClick={handleResetJewel} className="nav__position-button">
              Reset JEWEL
            </button>
            <button
              onClick={handleRemoveJewel}
              className="nav__position-button"
            >
              Remove JEWEL
            </button>
          </div>

          <div className="nav__range-wrapper">
            <button
              onClick={handleClosePositions}
              className="nav__position-button"
            >
              ADD ANOTHER JEWEL
            </button>
          </div>
        </div>
      </div>

      <div
        className={`nav__add-jewel-wrapper ${selectedJewel ? "" : "active"}`}
      >
        <h1 className="nav__add-jewel-title">
          {hoveredJewel ? hoveredJewel : "ADD A JEWEL"}
        </h1>
        <div className="nav__add-jewel-button-wrapper">
          <button
            className="nav__add-jewel-button"
            onClick={handleAddJewel}
            data-value={"square"}
            data-title={"CARRÉ"}
            onPointerEnter={handleMouseEnter}
            onPointerLeave={handleMouseLeave}
          >
            <img
              src="/assets/Box_trans.png"
              className="nav__jewel-image"
              alt="CARRÉ"
            />
          </button>

          <button
            className="nav__add-jewel-button"
            onClick={handleAddJewel}
            data-value={"circleSmall"}
            data-title={"PETIT ROND"}
            onPointerEnter={handleMouseEnter}
            onPointerLeave={handleMouseLeave}
          >
            <img
              src="/assets/Circle-Small_trans.png"
              className="nav__jewel-image"
              alt="PETIT ROND"
            />
          </button>
          <button
            className="nav__add-jewel-button"
            onClick={handleAddJewel}
            data-value={"line"}
            data-title={"LIGNE"}
            onPointerEnter={handleMouseEnter}
            onPointerLeave={handleMouseLeave}
          >
            <img
              src="/assets/Rectangle_trans.png"
              className="nav__jewel-image"
              alt="LIGNE"
            />
          </button>
          <button
            className="nav__add-jewel-button"
            onClick={handleAddJewel}
            data-value={"heart"}
            data-title={"COEUR"}
            onPointerEnter={handleMouseEnter}
            onPointerLeave={handleMouseLeave}
          >
            <img
              src="/assets/Heart_trans.png"
              className="nav__jewel-image"
              alt="COEUR"
            />
          </button>
          <button
            className="nav__add-jewel-button"
            onClick={handleAddJewel}
            data-value={"circleLarge"}
            data-title={"GRAND ROND"}
            onPointerEnter={handleMouseEnter}
            onPointerLeave={handleMouseLeave}
          >
            <img
              src="/assets/Circle-Large_trans.png"
              className="nav__jewel-image"
              alt="GRAND ROND"
            />
          </button>
        </div>
        <h1 className="nav__add-jewel-title">
          CLICK & DRAG TO POSITION YOUR JEWEL
        </h1>
        <div className="nav__preview-wrapper">
          {/* <Link to={"/preview"} className="nav__generic-button">
            PREVIEW DESIGN
          </Link> */}
          <button className={"nav__generic-button"} onClick={handleClearJewels}>
            Clear Jewels
          </button>
        </div>
      </div>
    </>
  );
};

export default Home;
