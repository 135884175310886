import { useFrame } from "@react-three/fiber";
import { lerp } from "three/src/math/MathUtils";
import useWindowSize from "../../hooks/useWindowSize";

const Animations = (props) => {
  const { selectedJewel, modelsGroup } = props;

  const windowSize = useWindowSize();

  return useFrame(({ mouse }) => {
    if (windowSize.width >= 750) {
      if (selectedJewel) {
        modelsGroup.current.rotation.x = lerp(
          modelsGroup.current.rotation.x,
          0,
          0.08
        );
        modelsGroup.current.rotation.y = lerp(
          modelsGroup.current.rotation.y,
          0,
          0.08
        );
      } else {
        modelsGroup.current.rotation.x = lerp(
          modelsGroup.current.rotation.x,
          -mouse.y * 0.07,
          0.08
        );
        modelsGroup.current.rotation.y = lerp(
          modelsGroup.current.rotation.y,
          mouse.x * 0.07,
          0.08
        );
      }
    }
  });
};

export default Animations;
