import { useState, useEffect } from "react";
import { PortableText } from "@portabletext/react";
import { UnderlineLink } from "../hooks/usePortableTextComponents";
import sanityClient from "../client";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { getFile } from "@sanity/asset-utils";

import "./About.scss";
import ProgressiveImage from "../components/global/ProgressiveImage";
import Loading from "../components/global/Loading";

const About = () => {
  const [pageData, setPageData] = useState(null);
  const [imageTitle, setImageTitle] = useState('');

  const handleMouseEnter = (e) => {
    setImageTitle(e.target.dataset.title);
  }
  const handleMouseLeave = (e) => {
    setImageTitle('')
  }

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "about"][0]`)
      .then((data) => {
        setPageData(data);
      })
      .catch();
  }, []);

  if (pageData) {
    return (
      <motion.main
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.4 } }}
        exit={{ opacity: 0, transition: { duration: 0.4 } }}
        className="about__outer-wrapper"
      >
        <Link to={"/"} className="about__back-button">
          &larr; BACK
        </Link>
        <section className="about__info-wrapper">
          <div className="about__product-header">
            <div>DOLLY COHEN</div>
            <div>PARIS</div>
          </div>
          <div className="about__product-description">
            <PortableText
              value={pageData.aboutText}
              components={UnderlineLink}
            />
          </div>
          <div className="about__socials-wrapper">
            {pageData.socials &&
              pageData.socials.map((social, index) => {
                return (
                  <a
                    href={
                      social._type === "emailLink"
                        ? `mailto:${social.entryLink}`
                        : social.entryLink
                    }
                    target="_blank"
                    rel="noreferrer"
                    key={index}
                    className="about__social-link"
                  >
                    {social.entryName}
                  </a>
                );
              })}
          </div>
          <h1 className="about__image-title">{imageTitle}</h1>
        </section>
        <section className="about__media-wrapper">
          {pageData.pageBuilder &&
            pageData.pageBuilder.map((media, index) => {
              const type = media._type;
              if (type === "imageMedia") {
                return (
                  <div 
                  className="about__media-item-wrapper" 
                  key={index} 
                  data-title={media.title}
                  onMouseMove={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  >
                    <ProgressiveImage
                      classNames={"about__media-item"}
                      imageSrc={media.imageMedia}
                      alt={media.title}
                    />
                  </div>
                );
              } else {
                const file = getFile(media.videoMedia, sanityClient.config());
                return (
                  <div className="about__media-item-wrapper" key={index}>
                    <video
                      src={file.asset.url}
                      autoPlay
                      background={true}
                      muted={true}
                      playsInline={true}
                      loop={true}
                      className={"about__media-item"}
                    />
                  </div>
                );
              }
            })}
        </section>
      </motion.main>
    );
  } else {
    return <Loading />
  }
};

export default About;
