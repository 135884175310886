import { useLoader } from "@react-three/fiber";
import {  TextureLoader, EquirectangularReflectionMapping, DoubleSide, } from "three";

const Silver = () => {

const envMap = useLoader(TextureLoader, "./3D/textures/hdricopy.png");
envMap.mapping = EquirectangularReflectionMapping;

  return (
    <meshPhongMaterial
    color={0x000000}
    envMap={envMap}
    reflectivity={1}
    shininess={100}
    side={DoubleSide}
    />
  )
}

export default Silver