import { useEffect, useState } from "react";
import sanityClient from "../client";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import "./Shop.scss";
import ProgressiveImage from "../components/global/ProgressiveImage";
import Loading from "../components/global/Loading";

const Shop = () => {
  const [products, setProducts] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "products"]`)
      .then((data) => setProducts(data))
      .catch(console.error);
  }, []);

  if(products){
    return (
      <motion.main
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.4 } }}
        exit={{ opacity: 0, transition: { duration: 0.4 } }}
        className="shop__outer-wrapper"
      >
        <Link to={"/"} className="shop__back-button">&larr; BACK</Link>
        <div className="shop__inner-wrapper">
          {products &&
            products.map((product, index) => {
              return (
                <Link
                to={`/products/${product.productSlug.current}`}
                key={index}
                className="shop__item-wrapper"
              >
                <ProgressiveImage 
                  imageSrc={product.mainImage}
                  classNames={"shop__product-image"}
                  alt={product.title}
                />
                <div className="shop__product-title">{product.title}</div>
              </Link>
              );
            })}
        </div>
      </motion.main>
    );
  } else {
    return <Loading />
  }

};

export default Shop;
