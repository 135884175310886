import { useState, useEffect } from "react";
import { PortableText } from "@portabletext/react";
import { UnderlineLink } from "../hooks/usePortableTextComponents";
import { useParams } from "react-router-dom";
import sanityClient from "../client";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { getFile } from "@sanity/asset-utils";

import BuyButton from "../components/product/BuyButton";

import "./Product.scss";
import ProgressiveImage from "../components/global/ProgressiveImage";
import Loading from "../components/global/Loading";

const Product = (props) => {
  const { shopifyClient, ui } = props;
  const { slug } = useParams();
  const [productData, setProductData] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[productSlug.current == $slug]{
                _id,
                title,
                productSlug,
                productDescription,
                shopifyID,
                price,
                mainImage,
                pageBuilder
              }`,
        { slug }
      )
      .then((data) => {
        setProductData(data[0]);
      })
      .catch();
  }, [slug]);

  if (productData) {
    return (
      <motion.main
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.4 } }}
        exit={{ opacity: 0, transition: { duration: 0.4 } }}
        className="product__outer-wrapper"
      >
        <Link to={"/shop"} className="product__back-button">
          &larr; BACK
        </Link>
        <section className="product__info-wrapper">
          <div className="product__product-header">
            <div>{productData.title}</div>
            <div>${productData.price}</div>
          </div>
          <div className="product__product-description">
            <PortableText
              value={productData.productDescription}
              components={UnderlineLink}
            />
            <div className="product__terms">
              By purchasing a Dolly &trade; you agree to the{" "}
              <Link to={"/terms"}>Terms & Conditions</Link>
            </div>
          </div>
          <BuyButton
            shopifyClient={shopifyClient}
            ui={ui}
            productData={productData}
          />
        </section>
        <section className="product__media-wrapper">
          <div className="product__media-item-wrapper">
            <ProgressiveImage
              classNames={"product__media-item"}
              imageSrc={productData.mainImage}
              alt={productData.title}
            />
          </div>
          {productData.pageBuilder &&
            productData.pageBuilder.map((media, index) => {
              const type = media._type;
              if (type === "imageMedia") {
                return (
                  <div className="product__media-item-wrapper" key={index}>
                    <ProgressiveImage
                      classNames={"product__media-item"}
                      imageSrc={media.imageMedia}
                      alt={productData.title}
                    />
                  </div>
                );
              } else {
                const file = getFile(media.videoMedia, sanityClient.config());
                return (
                  <div className="product__media-item-wrapper" key={index}>
                    <video
                      src={file.asset.url}
                      autoPlay
                      background={true}
                      muted={true}
                      playsInline={true}
                      loop={true}
                      className={"product__media-item"}
                    />
                  </div>
                );
              }
            })}
        </section>
      </motion.main>
    );
  } else {
    return <Loading />;
  }
};

export default Product;
