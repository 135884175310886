import { useGLTF } from "@react-three/drei";
import { useEffect } from "react";
import TeethTexture from "../../textures/TeethTexture";

export function TeethOne(props) {
  const { backgroundColor, setRaycasterObjects, top, bottom } = props;
  const { nodes } = useGLTF("/3D/models/teeth1.gltf");

  useEffect(() => {
    setRaycasterObjects([top.current, bottom.current]);
  }, [top, bottom, setRaycasterObjects]);

  return (
    <group {...props} dispose={null} position={[0, -3.15, -3.7]}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mold_Top_pivot.geometry}
          position={[0, 1.77, -0.14]}
          rotation={[0.43, 0, 0]}
          ref={top}
        >
          <TeethTexture backgroundColor={backgroundColor} />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mold_Bottom.geometry}
          ref={bottom}
        >
          <TeethTexture backgroundColor={backgroundColor} />
        </mesh>
      </group>
    </group>
  );
}

useGLTF.preload("/3D/models/teeth1.gltf");
