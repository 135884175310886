import { useState } from "react";
import { Link } from "react-router-dom";

import "./Footer.scss";

const Footer = (props) => {
  const { settingsData } = props;

  const currentYear = useState(() => new Date().getFullYear());

  if (settingsData) {
    return (
      <>
        {settingsData.showHide?(
        <div className="footer__nav-wrapper left">
          <Link to={"/shipping"} className="footer__nav-item">SHIPPING & RETURNS</Link>
          <Link to={"/terms"} className="footer__nav-item">TERMS & CONDITIONS</Link>
          <Link to={"/faq"} className="footer__nav-item">F.A.Qs</Link>
          <a href="https://mailchi.mp/dollycohen/dolly-cohen-newsletter" target='_blank' rel='noreferrer'>NEWSLETTER</a>
        </div>
      ):('')}

        {/* <div className="footer__nav-wrapper left">
          <Link to={"/shipping"} className="footer__nav-item">
            SHIPPING & RETURNS
          </Link>
          <Link to={"/terms"} className="footer__nav-item">
            TERMS & CONDITIONS
          </Link>
          <Link to={"/faq"} className="footer__nav-item">
            F.A.Qs
          </Link>
          <a
            href="https://mailchi.mp/dollycohen/dolly-cohen-newsletter"
            target="_blank"
            rel="noreferrer"
          >
            NEWSLETTER
          </a>
        </div> */}

        <div className="footer__nav-wrapper right">
          ALL CONTENT &copy; DOLLY COHEN {currentYear}. Site by&nbsp;
          <a href="https://voidmain.studio" target="_blank" rel="noreferrer">
            VOID MAIN()
          </a>
        </div>
      </>
    );
  }
};

export default Footer;
