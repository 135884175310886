import { useState, useEffect } from "react";
import { PortableText } from "@portabletext/react";
import { UnderlineLink } from "../hooks/usePortableTextComponents";
import sanityClient from "../client";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import "./Instructions.scss";
import ProgressiveImage from "../components/global/ProgressiveImage";
import Loading from "../components/global/Loading";

const Instructions = () => {
  const [pageData, setPageData] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "instructions"][0]`)
      .then((data) => {
        setPageData(data);
      })
      .catch();
  }, []);

  if (pageData) {
    return (
      <motion.main
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.4 } }}
        exit={{ opacity: 0, transition: { duration: 0.4 } }}
        className="instructions__outer-wrapper"
      >
        <Link to={"/"} className="instructions__back-button">
          &larr; BACK
        </Link>
        <section className="instructions__info-wrapper">
          <div className="instructions__product-header">
            <div>APPLICATION INSTRUCTIONS</div>
          </div>
          <div className="instructions__product-description">
            <PortableText
              value={pageData.aboutText}
              components={UnderlineLink}
            />
          </div>
        </section>
        <section className="instructions__media-wrapper">
          {pageData.pageBuilder &&
            pageData.pageBuilder.map((media, index) => {
              const type = media._type;
              if (type === "imageMedia") {
                return (
                  <div 
                  className="instructions__media-item-wrapper" 
                  key={index}
                  >
                    <ProgressiveImage
                      classNames={"instructions__media-item"}
                      imageSrc={media.imageMedia}
                      alt={media.title}
                    />
                  </div>
                );
              } else {
                return;
              }
            })}
        </section>
      </motion.main>
    );
  } else {
    return <Loading />
  }
};

export default Instructions;
