import "./Loading.scss"

const Loading = (props) => {

  return (
    <div className={`loading__outer-wrapper`}>
      <div className="loading__inner-wrapper">
        <img src="/assets/DollyCohen_Script.svg" alt="DOLLY COHEN" className="loading__logo"/>
        <div className="loading__text">LOADING</div>
      </div>
    </div>
  )
}

export default Loading