import { useState, useEffect, useRef } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import sanityClient from "./client";

import ShopifyBuy from "@shopify/buy-button-js";

import Header from "./components/global/Header";
import Footer from "./components/global/Footer";
import Home from "./views/Home";
import Shop from "./views/Shop";
import About from "./views/About";
import Shipping from "./views/Shipping";
import Terms from "./views/Terms";
import Instructions from "./views/Instructions";
import Product from "./views/Product";
import Faq from "./views/Faq";
import Error from "./views/Error";

import { AnimatePresence } from "framer-motion";

import "./styles/global-styles.scss";
import Scene from "./components/3D/Scene";
import Preloader from "./components/global/Preloader";
import Cart from "./components/global/Cart";
// import Preview from "./views/Preview";

import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";



const App = () => {
  const location = useLocation();

  const firebaseConfig = {
    apiKey: "AIzaSyBbTPcy1hvpqsxn0mZk58TnggZHSP5wpgg",
    authDomain: "dolly-cohen.firebaseapp.com",
    projectId: "dolly-cohen",
    storageBucket: "dolly-cohen.appspot.com",
    messagingSenderId: "890783675141",
    appId: "1:890783675141:web:366f23860846b9b204fd6c",
    measurementId: "G-6ED7DTE4ZV"
  };

  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  logEvent(analytics, 'notification_received');

  const [settingsData, setSettingsData] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "siteSettings"][0]`)
      .then((data) => {
        setSettingsData(data);
      })
      .catch();
  }, []);

  const [shopifyClient, setShopifyClient] = useState({});
  const [ui, setUI] = useState({});

  useEffect(() => {
    const shopifyClientInstance = ShopifyBuy.buildClient({
      domain: "dolly-cohen.myshopify.com",
      storefrontAccessToken: "064d8ae2d900852e86f4ca210916beeb",
    });

    setShopifyClient(shopifyClientInstance);

    const uiInstance = ShopifyBuy.UI.init(shopifyClientInstance);
    setUI(uiInstance);
  }, []);

  useEffect(() => {
    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--app-height", `${window.innerHeight}px`);
    };
    window.addEventListener("resize", appHeight);
    appHeight();
    return () => window.removeEventListener("resize", appHeight);
  }, []);

  // RAYCASTER
  const [raycasterObjects, setRaycasterObjects] = useState([]);
  const [isDragging, setIsDragging] = useState(false);

  // JEWELS
  const [jewelsArray, setJewelsArray] = useState([
    {
      name: "heart",
      stageName: "COEUR",
      position: [
        -0.5052190586193291, 0.041461746452042814, -0.08567989126072906,
      ],
      rotation: [-0.0878026656694041, -0.21813050694740183, 0],
      index: 0,
    },
  ]);
  const [selectedJewel, setSelectedJewel] = useState(null);
  const [selectedJewelIndex, setSelectedJewelIndex] = useState(null);

  //TEETH
  // const [selectedTeeth, setSelectedTeeth] = useState(1);
  const [selectedTeeth] = useState(1);

  // MISC
  const [loadingProgress, setLoadingProgress] = useState(false);
  // const [backgroundColor, setBackgroundColor] = useState("white");
  // const [foregroundColor, setForegroundColor] = useState("black");
  const [backgroundColor] = useState("white");
  const [foregroundColor] = useState("black");

  const modelsGroup = useRef();
  return (
    <AnimatePresence>
      <Preloader loadingProgress={loadingProgress} />
      <Header shopifyClient={shopifyClient} ui={ui} settingsData={settingsData} />
      <Cart shopifyClient={shopifyClient} ui={ui} />
      <Scene
        {...{
          raycasterObjects,
          setRaycasterObjects,
          isDragging,
          setIsDragging,
          jewelsArray,
          setJewelsArray,
          selectedJewel,
          setSelectedJewel,
          selectedJewelIndex,
          setSelectedJewelIndex,
          selectedTeeth,
          loadingProgress,
          setLoadingProgress,
          backgroundColor,
          foregroundColor,
          modelsGroup,
        }}
      />
      <Routes location={location} key={location.pathname}>
        <Route
          element={
            <Home
              key={"home"}
              {...{
                jewelsArray,
                setJewelsArray,
                selectedJewel,
                setSelectedJewel,
                selectedJewelIndex,
              }}
            />
          }
          path="/"
        />
        <Route element={<Shop key={"shop"} />} path="/shop" />
        <Route
          element={
            <Product key={"product"} shopifyClient={shopifyClient} ui={ui} />
          }
          path="/products/:slug"
        />
        <Route element={<About key={"about"} />} path="/about" />
        <Route element={<Shipping key={"shipping"} />} path="/shipping" />
        <Route element={<Terms key={"terms"} />} path="/terms" />
        <Route
          element={<Instructions key={"instructions"} />}
          path="/instructions"
        />
        <Route element={<Faq key={"faq"} />} path="/faq" />
        {/* <Route
          element={
            <Preview
              key={"preview"}
              {...{
                jewelsArray,
              }}
            />
          }
          path="/preview"
        /> */}
        <Route element={<Error key={"error"} />} path="*" />
      </Routes>
      <Footer settingsData={settingsData} />
    </AnimatePresence>
  );
};

export default App;
