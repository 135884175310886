import { Link } from "react-router-dom";

import "./Header.scss";

const Header = (props) => {
  const { ui, settingsData } = props;

  const handleClick = (e) => {
    e.stopPropagation();
    ui.openCart();
  };

  if (settingsData) {
    return (
      <>
        <Link to={"/"} className="header__logo-wrapper">
          <img
            src="/assets/DollyCohen_Text.svg"
            alt="DOLLY COHEN"
            className="header__logo"
          />
        </Link>

        {/* <div className="header__nav-wrapper">
            <Link to={"/shop"} className="header__nav-item">
              SHOP
            </Link>
            <Link to={"/about"} className="header__nav-item">
              ABOUT
            </Link>
            <Link to={"/instructions"} className="header__nav-item">
              INSTRUCTIONS
            </Link>
            <button className="header__nav-item" onClick={handleClick}>
              CART
            </button>
          </div> */}

        {settingsData.showHide ? (
          <div className="header__nav-wrapper">
            <Link to={"/shop"} className="header__nav-item">
              SHOP
            </Link>
            <Link to={"/about"} className="header__nav-item">
              ABOUT
            </Link>
            <Link to={"/instructions"} className="header__nav-item">
              INSTRUCTIONS
            </Link>
            <button className="header__nav-item" onClick={handleClick}>
              CART
            </button>
          </div>
        ) : (
          <>
            <div className="header__pre-launch">
              <h1>{settingsData.timeText}</h1>
              <h1>
                Sign up to the{" "}
                <a
                  href="https://mailchi.mp/dollycohen/dolly-cohen-newsletter"
                  target="_blank"
                  rel="noreferrer"
                >
                  Newsletter
                </a>{" "}
                for more info.
              </h1>
            </div>
            <div className="header__pre-launch-about">
              <Link to={"/about"} className="header__nav-item">
                ABOUT
              </Link>
            </div>
          </>
        )}
      </>
    );
  }
};

export default Header;
