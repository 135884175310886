import { useLoader } from "@react-three/fiber";
import {  TextureLoader, EquirectangularReflectionMapping, DoubleSide, } from "three";

const Gold = () => {

const envMap = useLoader(TextureLoader, "/3D/textures/hdri5.png");
envMap.mapping = EquirectangularReflectionMapping;

  return (
    <meshStandardMaterial
    // color={0xFFD700}
    color={0xf6d586}
    envMap={envMap}
    metalness={1}
    roughness={0}
    envMapIntensity={0.8}
    side={DoubleSide}
    />
  )
}

export default Gold