import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import "./Error.scss";

const Error = () => {
 
    return (
      <motion.main
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.4 } }}
        exit={{ opacity: 0, transition: { duration: 0.4 } }}
        className="error__outer-wrapper"
      >
        <Link to={"/"} className="error__back-button">
          &larr; BACK
        </Link>
        <h1>ERROR 404...<br />WE CAN'T FINT THE PAGE YOU'RE LOOKING FOR</h1>
      </motion.main>
    );
};

export default Error;