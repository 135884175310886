import { useEffect, useRef } from "react";

const degToRad = (value) => {
  return (value * Math.PI) / 180;
};

const JewelRotation = (props) => {
  const { jewelsArray, setJewelsArray, selectedJewelIndex } = props;

  const rotZ = useRef();
  const rotInterval = useRef();

  const handleRotZ = (e) => {
    const oldPos = jewelsArray[selectedJewelIndex].rotation;
    rotInterval.current = setInterval(() => {
        const newArray = [...jewelsArray];
        const newPos = degToRad(e.target.value);
      newArray[selectedJewelIndex].rotation = [oldPos[0], oldPos[1], newPos];
      setJewelsArray(newArray);
    }, 10);
  };

  const handlePointerUp = () => {
    clearInterval(rotInterval.current);
  };

  useEffect(() => {
    window.addEventListener("pointerup", handlePointerUp);
    return window.removeEventListener("pointerup", handlePointerUp);
  });

  return (
    <div className="nav__range-wrapper">
      <h1>Rotate JEWEL</h1>
      <input
        data-value={2}
        type="range"
        min={-180}
        max={180}
        ref={rotZ}
        onPointerDown={handleRotZ}
        onPointerUp={handlePointerUp}
        className="nav__range"
      />
    </div>
  );
};

export default JewelRotation;
