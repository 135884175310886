import { useRef } from "react";

import { TeethOne } from "./Teeth1";
// import { TeethTwo } from "./Teeth2";
// import { TeethThree } from "./Teeth3";

const Teeth = (props) => {
  const { 
    selectedTeeth,
    setRaycasterObjects,
    backgroundColor,
  } = props;

  const components = {
    1: TeethOne,
    // 2: TeethTwo,
    // 3: TeethThree,
  };
  const Component = components[selectedTeeth];

  const top = useRef();
  const bottom = useRef();

  return (
      <Component 
      backgroundColor={backgroundColor} 
      setRaycasterObjects={setRaycasterObjects} 
      top={top}
      bottom={bottom}
      />
  );
};

export default Teeth;

